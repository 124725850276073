// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-criacao-de-aplicativos-em-tempo-recorde-js": () => import("./../../../src/pages/criacao-de-aplicativos-em-tempo-recorde.js" /* webpackChunkName: "component---src-pages-criacao-de-aplicativos-em-tempo-recorde-js" */),
  "component---src-pages-criacao-de-software-sob-medida-sem-complicacao-js": () => import("./../../../src/pages/criacao-de-software-sob-medida-sem-complicacao.js" /* webpackChunkName: "component---src-pages-criacao-de-software-sob-medida-sem-complicacao-js" */),
  "component---src-pages-desenvolvimento-de-software-sem-complicacao-js": () => import("./../../../src/pages/desenvolvimento-de-software-sem-complicacao.js" /* webpackChunkName: "component---src-pages-desenvolvimento-de-software-sem-complicacao-js" */),
  "component---src-pages-entraremos-em-contato-js": () => import("./../../../src/pages/entraremos-em-contato.js" /* webpackChunkName: "component---src-pages-entraremos-em-contato-js" */),
  "component---src-pages-estimativa-final-app-js": () => import("./../../../src/pages/estimativa-final-app.js" /* webpackChunkName: "component---src-pages-estimativa-final-app-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-outsourcing-em-desenvolvimento-linkedin-js": () => import("./../../../src/pages/outsourcing-em-desenvolvimento-linkedin.js" /* webpackChunkName: "component---src-pages-outsourcing-em-desenvolvimento-linkedin-js" */),
  "component---src-pages-quanto-custa-um-app-js": () => import("./../../../src/pages/quanto-custa-um-app.js" /* webpackChunkName: "component---src-pages-quanto-custa-um-app-js" */)
}

